import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
  description: string
  index?: boolean
  follow?: boolean
  canonical?: string
}

const SEO = ({ title, description, index, follow, canonical }: Props) => (
  <Helmet
    htmlAttributes={{
      lang: 'es',
    }}
  >
    <meta charSet="utf-8" />
    <meta
      name="robots"
      content={`${index ? 'index' : 'noindex'},${
        follow ? 'follow' : 'nofollow'
      }`}
    />
    <title>💰 {title} 🥇| Trisquete.com</title>
    <meta name="description" content={description} />
    {canonical && <link rel="canonical" href={canonical} />}

    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="article" />
    {canonical && <meta property="og:url" content={canonical} />}
    <meta property="og:locale" content="es_AR" />
    <meta property="og:locale:alternate" content="es_ES" />
    <meta property="og:locale:alternate" content="es_MX" />
    <meta property="og:locale:alternate" content="es_CO" />
    <meta property="og:site_name" content="Trisquete" />

    <meta property="og:image" content="https://example.com/ogp.jpg" />
    <meta
      property="og:image:secure_url"
      content="https://secure.example.com/ogp.jpg"
    />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="400" />
    <meta property="og:image:height" content="300" />
    <meta
      property="og:image:alt"
      content="A shiny red apple with a bite taken out"
    />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@trisquetecom" />
    <meta name="twitter:creator" content="@trisquetecom" />
  </Helmet>
)

SEO.defaultProps = {
  index: true,
  follow: true,
  canonical: null,
}

export default SEO
